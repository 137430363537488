type Tenant = 'fitx' | 'gymlab' | 'pilot-fitx';

export type Feature =
    | 'MYSPORTS_MESSAGE'
    | 'COMM_MATRIX_DATA_PRIVACY'
    | 'COMM_MATRIX_MYSPORTS_RENAME'
    | 'EXTRAORDINARY_CANCELLATION_ALTERNATIVE_HELPER_TEXT'
    | 'FITX_ADDITIONAL_PRIVACY_INFORMATION';

interface TenantFeatureFlagsWildCard {
    '*': boolean;
}

type TenantFeatureFlags = { [key in Tenant]?: boolean } &
    TenantFeatureFlagsWildCard;

export type FeatureFlag = boolean | TenantFeatureFlags;

export type FeatureFlags = {
    [key in Feature]: FeatureFlag;
};

export const featuresProd: FeatureFlags = {
    MYSPORTS_MESSAGE: true,
    COMM_MATRIX_DATA_PRIVACY: {
        '*': false,
        fitx: true,
        'pilot-fitx': true,
        gymlab: true
    },
    COMM_MATRIX_MYSPORTS_RENAME: {
        '*': false,
        fitx: true,
        'pilot-fitx': true,
        gymlab: true
    },
    EXTRAORDINARY_CANCELLATION_ALTERNATIVE_HELPER_TEXT: {
        '*': false,
        fitx: true,
        'pilot-fitx': true,
        gymlab: true
    },
    FITX_ADDITIONAL_PRIVACY_INFORMATION: {
        '*': false,
        fitx: true,
        'pilot-fitx': true,
        gymlab: true
    }
};

export const featuresStage: FeatureFlags = {
    MYSPORTS_MESSAGE: true,
    COMM_MATRIX_DATA_PRIVACY: true,
    COMM_MATRIX_MYSPORTS_RENAME: true,
    EXTRAORDINARY_CANCELLATION_ALTERNATIVE_HELPER_TEXT: true,
    FITX_ADDITIONAL_PRIVACY_INFORMATION: true
};
