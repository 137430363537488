import { bind } from 'decko';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react'; // preact-compat
import { external, inject } from 'tsdi';

import { ChannelCheckbox } from './channel-checkbox';
import {
    Channel,
    CHANNEL_ALL,
    CommMatrixStore,
    Topic
} from './commmatrix-store';
import { ColumnLong, LabelRow, Text, TopicRowWrapper } from './styles';

interface TopicRowProps {
    topic: Topic;
}

interface ChannelCheckboxData extends Channel {
    isChecked: boolean;
    isDisabled: boolean;
    toggleIsChecked(): void;
}

@observer
@external
export class TopicRow extends React.Component<TopicRowProps> {
    @inject
    private commMatrixStore!: CommMatrixStore;

    @computed
    private get allChannelsEnabled(): boolean {
        const { numberOfAllChannels } = this.commMatrixStore;
        return this.props.topic.activeChannels.length === numberOfAllChannels;
    }

    @computed
    private get allChannelsDisabled(): boolean {
        const { numberOfAllChannels } = this.commMatrixStore;
        return this.props.topic.disabledChannels.length === numberOfAllChannels;
    }

    @bind
    private onAllClick(): void {
        const { databaseId = 0 } = this.props.topic;
        this.allChannelsEnabled
            ? this.commMatrixStore.disableAllChannels(databaseId)
            : this.commMatrixStore.enableAllChannels(databaseId);
    }

    @computed
    private get channelsCheckboxes(): ChannelCheckboxData[] {
        const { channels } = this.commMatrixStore;
        const {
            activeChannels,
            disabledChannels,
            databaseId
        } = this.props.topic;

        return channels.map(channel => {
            const isDisabled = disabledChannels.includes(channel.key);
            const isChecked = (() => {
                return activeChannels.includes(channel.key);
            })();
            const toggleIsChecked = () =>
                this.commMatrixStore.toggleTopicEnabledChannel(
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    databaseId!,
                    channel.key
                );

            return {
                ...channel,
                isChecked,
                isDisabled,
                toggleIsChecked
            };
        });
    }

    public render(): JSX.Element | null {
        const { label, text, databaseId, showAsterisk } = this.props.topic;
        const { allChannelsDisabled } = this;

        if (!databaseId) {
            return null;
        }

        const labelText = showAsterisk ? `${label} (*)` : label;

        return (
            <TopicRowWrapper>
                <ColumnLong>
                    <LabelRow>{labelText}</LabelRow>
                    <Text>{text}</Text>
                </ColumnLong>
                <ChannelCheckbox
                    label={CHANNEL_ALL.label}
                    isChecked={this.allChannelsEnabled}
                    onClick={this.onAllClick}
                    isDisabled={allChannelsDisabled}
                    data-role={`${CHANNEL_ALL.label}@${label}`}
                />
                {this.channelsCheckboxes.map(
                    (checkbox: ChannelCheckboxData) => (
                        <ChannelCheckbox
                            key={checkbox.label}
                            label={checkbox.label}
                            isChecked={checkbox.isChecked}
                            isDisabled={checkbox.isDisabled}
                            onClick={checkbox.toggleIsChecked}
                            data-role={`${checkbox.label}@${label}`}
                        />
                    )
                )}
            </TopicRowWrapper>
        );
    }
}
