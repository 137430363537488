import { Pretend, IPretendRequest, Get, Post, Put } from 'pretend';

import * as dtos from './dtos';
import { parseApiDates } from './date';

// copied from https://github.com/developit/decko/blob/master/src/decko.js
const bind = <T extends Function>(
    _target: Object,
    key: string | symbol,
    { value: fn }: TypedPropertyDescriptor<T>
): TypedPropertyDescriptor<T> | void => {
    let definingProperty = false;
    return {
        configurable: true,
        get() {
            if (definingProperty) {
                return fn;
            }
            const value = fn!.bind(this);
            definingProperty = true;
            Object.defineProperty(this, key, {
                value,
                configurable: true,
                writable: true
            });
            definingProperty = false;
            return value;
        }
    };
};

const parseResult = (props: string[]) => {
    return (
        _target: Object,
        _key: string | symbol,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        descriptor: TypedPropertyDescriptor<any>
    ) => {
        const fn = descriptor.value;

        descriptor.value = async function(...args: unknown[]) {
            const result = await fn.apply(this, args);

            const tupleProps = props.map(prop => prop.split('.'));

            return parseApiDates(result, tupleProps);
        };

        return descriptor;
    };
};

export interface ConnectApiAdditionalAgreementController {
    getAdditionalAgreement(
        customerId: number
    ): Promise<dtos.ConnectApiAdditionalAgreementDto>;

    setAdditionalAgreement(customerId: number): Promise<void>;
}

export interface ConnectApiAuthenticationController {
    /**
     * @deprecated
     */
    grantAccess(
        encryptedCustomerId: string,
        query: { hash: string }
    ): Promise<dtos.AuthDto>;

    grantAccessUuid(uuid: string): Promise<dtos.AuthDto>;
}

export interface ConnectApiBookingController {
    cancelBooking(
        bookingId: string
    ): Promise<dtos.ConnectApiBookingConfirmationDto>;

    confirmBooking(
        bookingId: string
    ): Promise<dtos.ConnectApiBookingConfirmationDto>;
}

export interface ConnectApiCommMatrixController {
    getCommMatrix(
        customerId: number
    ): Promise<dtos.CustomerCommunicationConfigurationDto[]>;

    getPrivacyUrl(customerId: number): Promise<dtos.WrappedResultString>;

    updateCommMatrix(
        customerId: number,
        dto: dtos.CustomerCommunicationConfigurationDto[]
    ): Promise<dtos.CustomerCommunicationConfigurationDto[]>;

    updateCommMatrixFull(
        customerId: number,
        dto: dtos.CustomerCommunicationConfigurationDto[]
    ): Promise<dtos.CustomerCommunicationConfigurationDto[]>;
}

export interface ConnectApiContractController {
    cancelContract(
        query: { recaptchaToken: string },
        dto: dtos.CancelContractRequestDto
    ): Promise<dtos.CancelContractResponseDto>;

    cancelContractManual(
        query: { recaptchaToken: string },
        dto: dtos.CancelContractManualRequestDto
    ): Promise<dtos.CancelContractManualResponseDto>;

    getActiveContracts(
        query: { recaptchaToken: string },
        dto: dtos.GetActiveContractsRequestDto
    ): Promise<dtos.GetActiveContractsResponseDto[]>;

    getStudioInfo(
        studioId: number
    ): Promise<dtos.ConnectApiContractCancellationStudioDto>;
}

export interface ConnectApiContractControllerV2 {
    getActiveStudiosInGermanyV2(): Promise<
        dtos.ConnectApiBasicStudioDataDtoV2[]
    >;
}

export interface ConnectApiPromotionController {
    confirmCustomerParticipation(query: { payload: string }): Promise<void>;
}

export interface ConnectApiRateBundleController {
    createCustomerAndContract(
        dto: dtos.ConnectApiCreateContractDto
    ): Promise<dtos.ConnectApiCreateContractResponseDto>;

    getRateBundleList(query: {
        studioId: number;
    }): Promise<dtos.ConnectApiRateBundleDto[]>;
}

export interface ConnectApiTenantController {
    getTenantInfo(): Promise<dtos.TenantDto>;
}

export interface StudioController {
    getDefaultCommunicationConfiguration(
        studioId: number
    ): Promise<dtos.ConnectApiStudioCommunicationConfigurationDto[]>;

    getSepaAgreementText(studioId: number): Promise<string>;

    getSepaAgreementTextForRateBundle(
        studioId: number,
        rateBundleId: number,
        query: { language?: string }
    ): Promise<string>;

    getUtilizationPublic(studioId: number): Promise<dtos.UtilizationDto>;
}

export interface StudioControllerV2 {
    getStudio(studioId: number): Promise<dtos.ConnectApiStudioDtoV2>;

    getStudioDetails(
        studioId: number
    ): Promise<dtos.ConnectApiStudioDetailsDto>;

    getStudioLegalLinks(
        studioId: number
    ): Promise<dtos.ConnectApiStudioLegalLinksDto>;

    getStudioList(query: {
        studioTags?: string[];
        addressSearchQuery?: string;
    }): Promise<dtos.ConnectApiStudioDtoV2[]>;
}

export interface PublicContentPigeon {
    ConnectApiAdditionalAgreementController: ConnectApiAdditionalAgreementController;
    ConnectApiAuthenticationController: ConnectApiAuthenticationController;
    ConnectApiBookingController: ConnectApiBookingController;
    ConnectApiCommMatrixController: ConnectApiCommMatrixController;
    ConnectApiContractController: ConnectApiContractController;
    ConnectApiContractControllerV2: ConnectApiContractControllerV2;
    ConnectApiPromotionController: ConnectApiPromotionController;
    ConnectApiRateBundleController: ConnectApiRateBundleController;
    ConnectApiTenantController: ConnectApiTenantController;
    StudioController: StudioController;
    StudioControllerV2: StudioControllerV2;
}

/* eslint-disable @typescript-eslint/no-explicit-any */

class ConnectApiAdditionalAgreementControllerBlueprint
    implements ConnectApiAdditionalAgreementController {
    @bind
    @parseResult(['agreementTime'])
    @Get('/connect/secured/agreement-setting/{customerId}')
    public getAdditionalAgreement(_p0: any): any {
        /**/
    }

    @bind
    @Post('/connect/secured/agreement-setting/{customerId}')
    public setAdditionalAgreement(_p0: any): any {
        /**/
    }
}

class ConnectApiAuthenticationControllerBlueprint
    implements ConnectApiAuthenticationController {
    @bind
    @Get('/connect/auth/grant-for-customer/{encryptedCustomerId}', true)
    public grantAccess(_p0: any, _p1: any): any {
        /**/
    }

    @bind
    @Get('/connect/auth/grant-for-customer-by/{uuid}')
    public grantAccessUuid(_p0: any): any {
        /**/
    }
}

class ConnectApiBookingControllerBlueprint
    implements ConnectApiBookingController {
    @bind
    @parseResult([
        'endDateTime',
        'startDateTime',
        'studio.closingDate',
        'studio.openingDate'
    ])
    @Post('/connect/v1/booking/{bookingId}/cancel')
    public cancelBooking(_p0: any): any {
        /**/
    }

    @bind
    @parseResult([
        'endDateTime',
        'startDateTime',
        'studio.closingDate',
        'studio.openingDate'
    ])
    @Post('/connect/v1/booking/{bookingId}/confirm')
    public confirmBooking(_p0: any): any {
        /**/
    }
}

class ConnectApiCommMatrixControllerBlueprint
    implements ConnectApiCommMatrixController {
    @bind
    @Get('/connect/secured/comm-setting/{customerId}')
    public getCommMatrix(_p0: any): any {
        /**/
    }

    @bind
    @Get('/connect/secured/comm-setting/{customerId}/privacy-url')
    public getPrivacyUrl(_p0: any): any {
        /**/
    }

    @bind
    @Put('/connect/secured/comm-setting/{customerId}')
    public updateCommMatrix(_p0: any, _p1: any): any {
        /**/
    }

    @bind
    @Put('/connect/secured/comm-setting/{customerId}/full')
    public updateCommMatrixFull(_p0: any, _p1: any): any {
        /**/
    }
}

class ConnectApiContractControllerBlueprint
    implements ConnectApiContractController {
    @bind
    @parseResult([
        'cancellationDate',
        'cancellationReceivedDateTime',
        'dateOfBirth'
    ])
    @Post('/connect/v1/contracts/cancel', true)
    public cancelContract(_p0: any, _p1: any): any {
        /**/
    }

    @bind
    @parseResult(['cancellationDate', 'dateOfBirth', 'requestReceivedDate'])
    @Post('/connect/v1/contracts/cancel-request', true)
    public cancelContractManual(_p0: any, _p1: any): any {
        /**/
    }

    @bind
    @parseResult(['cancellationDates'])
    @Post('/connect/v1/contracts', true)
    public getActiveContracts(_p0: any, _p1: any): any {
        /**/
    }

    @bind
    @Get('/connect/v1/contracts/studios/{studioId}')
    public getStudioInfo(_p0: any): any {
        /**/
    }
}

class ConnectApiContractControllerV2Blueprint
    implements ConnectApiContractControllerV2 {
    @bind
    @Get('/connect/v2/contracts/studios')
    public getActiveStudiosInGermanyV2(): any {
        /**/
    }
}

class ConnectApiPromotionControllerBlueprint
    implements ConnectApiPromotionController {
    @bind
    @Post('/connect/v1/promotion/confirm-participation', true)
    public confirmCustomerParticipation(_p0: any): any {
        /**/
    }
}

class ConnectApiRateBundleControllerBlueprint
    implements ConnectApiRateBundleController {
    @bind
    @Post('/connect/v1/rate-bundle')
    public createCustomerAndContract(_p0: any): any {
        /**/
    }

    @bind
    @parseResult([
        'limitedOfferingPeriod.endDate',
        'limitedOfferingPeriod.startDate',
        'terms.contractStartDateOfUse',
        'terms.defaultContractStartDate',
        'terms.subsequentRateDto.limitedOfferingPeriod.endDate',
        'terms.subsequentRateDto.limitedOfferingPeriod.startDate',
        'terms.subsequentRateDto.terms.contractStartDateOfUse',
        'terms.subsequentRateDto.terms.defaultContractStartDate',
        'terms.subsequentRateDto.terms.subsequentRateDto.limitedOfferingPeriod.endDate',
        'terms.subsequentRateDto.terms.subsequentRateDto.limitedOfferingPeriod.startDate',
        'terms.subsequentRateDto.terms.subsequentRateDto.terms.contractStartDateOfUse',
        'terms.subsequentRateDto.terms.subsequentRateDto.terms.defaultContractStartDate'
    ])
    @Get('/connect/v1/rate-bundle', true)
    public getRateBundleList(_p0: any): any {
        /**/
    }
}

class ConnectApiTenantControllerBlueprint
    implements ConnectApiTenantController {
    @bind
    @Get('/connect/v1/tenant')
    public getTenantInfo(): any {
        /**/
    }
}

class StudioControllerBlueprint implements StudioController {
    @bind
    @Get('/connect/v1/studio/{studioId}/communication-settings')
    public getDefaultCommunicationConfiguration(_p0: any): any {
        /**/
    }

    @bind
    @Get('/connect/v1/studio/{studioId}/sepa/agreement')
    public getSepaAgreementText(_p0: any): any {
        /**/
    }

    @bind
    @Get(
        '/connect/v1/studio/{studioId}/rate/{rateBundleId}/sepa/agreement',
        true
    )
    public getSepaAgreementTextForRateBundle(
        _p0: any,
        _p1: any,
        _p2: any
    ): any {
        /**/
    }

    @bind
    @Get('/connect/v1/studio/{studioId}/utilization')
    public getUtilizationPublic(_p0: any): any {
        /**/
    }
}

class StudioControllerV2Blueprint implements StudioControllerV2 {
    @bind
    @parseResult(['closingDate', 'openingDate'])
    @Get('/connect/v2/studio/{studioId}')
    public getStudio(_p0: any): any {
        /**/
    }

    @bind
    @parseResult(['closingDate', 'openingDate'])
    @Get('/connect/v2/studio/details/{studioId}')
    public getStudioDetails(_p0: any): any {
        /**/
    }

    @bind
    @Get('/connect/v2/studio/{studioId}/legalinfo')
    public getStudioLegalLinks(_p0: any): any {
        /**/
    }

    @bind
    @parseResult(['closingDate', 'openingDate'])
    @Get('/connect/v2/studio', true)
    public getStudioList(_p0: any): any {
        /**/
    }
}

/* eslint-enable @typescript-eslint/no-explicit-any */

export function getClient(
    endpoint: string,
    token: string | undefined,
    configure: (client: Pretend) => Pretend = client => client
): PublicContentPigeon {
    const bearerToken = (request: IPretendRequest) => {
        if (token) {
            request.options.headers = new Headers(request.options.headers);
            request.options.headers.set('Authorization', `bearer ${token}`);
        }
        return request;
    };

    return {
        ConnectApiAdditionalAgreementController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(ConnectApiAdditionalAgreementControllerBlueprint, endpoint),
        ConnectApiAuthenticationController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(ConnectApiAuthenticationControllerBlueprint, endpoint),
        ConnectApiBookingController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(ConnectApiBookingControllerBlueprint, endpoint),
        ConnectApiCommMatrixController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(ConnectApiCommMatrixControllerBlueprint, endpoint),
        ConnectApiContractController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(ConnectApiContractControllerBlueprint, endpoint),
        ConnectApiContractControllerV2: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(ConnectApiContractControllerV2Blueprint, endpoint),
        ConnectApiPromotionController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(ConnectApiPromotionControllerBlueprint, endpoint),
        ConnectApiRateBundleController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(ConnectApiRateBundleControllerBlueprint, endpoint),
        ConnectApiTenantController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(ConnectApiTenantControllerBlueprint, endpoint),
        StudioController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(StudioControllerBlueprint, endpoint),
        StudioControllerV2: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(StudioControllerV2Blueprint, endpoint)
    };
}
